import { FormikFieldWrapper } from 'app/styleguide/components/Formik';
import { Formik } from 'formik';
import React from 'react';
import IconSearch from 'styleguide/icons/IconSearch';

interface Props {
  placeholder: string;
  handleSearch: (value: string) => void;
  dataCy?: string;
}

const Search = ({ placeholder, handleSearch, dataCy }: Props) => (
  <Formik
    initialValues={{
      inputValue: '',
    }}
    onSubmit={() => {}}
    handl
  >
    {formikProps => (
      <FormikFieldWrapper
        componentType="input"
        name="inputValue"
        size="md"
        labelComponent={placeholder}
        labelClassName="!font-hvLite"
        iconPlacement="left"
        Icon={IconSearch}
        data-cy={dataCy}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          formikProps.setFieldValue('inputValue', e.target.value);
          handleSearch(e.target.value);
        }}
      />
    )}
  </Formik>
);

export default Search;
